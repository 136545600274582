<template>
  <a-modal v-model="visible" :title="form.ru && form.ru.name ? form.ru.name : 'Доп. характеристики'" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active" placeholder="выберите тип">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <p v-if="form.product_fields.length">Настройка полей</p>
      <div v-for="item in form.product_fields" :key="item.id" class="item">
        <p>{{ item.title }}</p>
        <template v-if="item.alias !== 'number_car' && item.alias !== 'vin_code_verified' && item.alias !== 'vin_code'">
          <a-form-model-item label="Название(ru)" required>
            <a-input  v-model.number="item.ru.name"/>
          </a-form-model-item>
          <a-form-model-item label="Название(ua)" required>
            <a-input  v-model.number="item.ua.name"/>
          </a-form-model-item>
        </template>

        <a-form-model-item label="Статус" required>
          <a-select v-model="item.active" placeholder="выберите тип">
            <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Позиция" required>
          <a-input v-model.number="item.position"/>
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>

import statuses from '@/entities/statuses'

export default {
  name: 'EditAutoBlockModal',
  data() {
    return {
      statuses,
      form: {
        product_fields: [],
      },
      visible: false,
    }
  },
  computed: {
    isDisabled() {
      const form = this.form
      let isDisabled = false

      if (form.product_fields.length) {
        for (const index in form.product_fields) {
          if (typeof form.product_fields[index] !== 'object' && !typeof form.product_fields[index]) isDisabled = true

          for (const item in form.product_fields[index]) {
            if (typeof form.product_fields[index][item] !== 'object' && !`${form.product_fields[index][item]}`) isDisabled = true

            if (typeof form.product_fields[index][item] === 'object') {
              for (const prop in form.product_fields[index][item]) {
                if (!`${form.product_fields[index][item][prop]}`) isDisabled = true
              }
            }
          }
        }
      }

      return isDisabled
    },
  },
  methods: {
    async open(id) {
      this.form = (await this.$services.get(`admin/blocks/${id}`)).data.data.value
      this.visible = true
    },
    async submit() {
      try {
        await this.$services.put(`admin/blocks/${this.form.id}`, this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.$emit('getList')
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
        product_fields: [],
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  .item {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 24px;
  }
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
