<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :row-key="(record, index) => index">
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >{{text}}</a>
      <span slot="name" slot-scope="record"> {{ record.title }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$refs.editModal.open(record.id)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
      </span>
    </a-table>
    <edit-auto-block-modal ref="editModal" @getList="$emit('getList')" />
  </div>
</template>

<script>

import EditAutoBlockModal from '@/components/content/pages/auto/EditAutoBlockModal'

export default {
  name: 'AutoBlocksTableSettings',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      marks: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название блока',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  created() {
    this.getMarks()
  },
  methods: {
    async getMarks() {
      try {
        this.marks = (await this.$services.get('/filters/marks?type=1')).data.data.marks
      } catch (e) {
        console.log(e)
      }
    },
    openModal(record) {
      if (record.alias) {
        this.$refs[record.alias].open(record)
      } else {
        this.$refs.blocks.open(record)
      }
    },
  },
  components: { EditAutoBlockModal },
}
</script>

<style scoped>

</style>
